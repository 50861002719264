<template>
  <div class="questionBank">
    <!-- 顶部导航 -->
    <nav-header btnId="test" :userInfo="userInfo"></nav-header>
    <!-- 题库列表 -->
    <div class="question-list">
      <ul class="list-ul">
        <li class="list-li" v-for="(item, index) in questionClass" :key="index">
          <div class="name">{{ item.name }}</div>
          <div class="num">共{{ item.questionCount }}道题</div>
          <div class="start-btn" @click="startDoQuestion(item)">开始做题</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
export default {
  name: "questionBank",
  components: {
    NavHeader,
  },
  data() {
    return {
      userInfo: null,
      questionClass: [],
    };
  },
  created() {
    this.getUserInfo();
    this.getClassList();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.axios
        .get("/loginInfo")
        .then((res) => {
          this.userInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取分类
    getClassList() {
      this.axios
        .get("/api/common/classification/anon/list", {
          params: {
            types: ["QUESTION"].toString(),
          },
        })
        .then((res) => {
          this.questionClass = res.data.QUESTION;
        });
    },
    //开始做题
    startDoQuestion(item) {
      if (!this.userInfo) {
        this.$message({
          message: "请登录",
          type: "warning",
        });
        return;
      }
      this.$router.push("/questionDetail?id=" + item.id + "&name=" + item.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.questionBank {
  display: flex;
  flex-direction: column;
  align-items: center;
  .question-list {
    width: 1160px;
    margin: 20px auto;
    background: #fff;
    .list-ul {
      list-style: none;
      .list-li {
        background: #f8f8f8;
        overflow: hidden;
        padding: 20px;
        margin-bottom: 20px;
        height: 30px;
        line-height: 30px;
        .name {
          float: left;
          width: 700px;
          float: left;
          font-size: 16px;
          color: #333;
          font-family: "Arial", "Helvetica Neue", Helvetica, "PingFang SC",
            "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        }
        .num {
          float: left;
          color: #999;
          font-size: 14px;
          font-family: "Arial", "Helvetica Neue", Helvetica, "PingFang SC",
            "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        }
        .start-btn {
          float: right;
          background: #f25051;
          color: #fff;
          padding: 0 20px;
          border-radius: 5px;
          border: 1px solid #f25051;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}
</style>